const isObject = (o: any) => o === Object(o) && !Array.isArray(o) && typeof o !== 'function';

let specialParameters: Record<string, string> = {};

const toCamel = (s: string) => {
    const badIntialCharacters = '$_ ';
    let modifiedParam = s;

    if (badIntialCharacters.includes(s[0]))
        // Parameters returning from MXD API sometimes start with '_ or $'.
        modifiedParam = s.substring(1);

    if (specialParameters[s]) {
        modifiedParam = specialParameters[s];
    } else {
        modifiedParam = modifiedParam.replace(/([-_][a-z0-9])/gi, ($1: string) =>
            $1.toUpperCase().replace('-', '').replace('_', ''),
        );
    }

    return modifiedParam;
};

const toSnakeCase = (s: string) => {
    const specialParameters = [
        'id',
        'createdBy',
        'createdAt',
        'updatedBy',
        'updatedAt',
        'date',
        'uid',
        'clientTempId',
        'isDirty',
        '_templateId',
    ];
    let modifiedParam = s;

    modifiedParam = modifiedParam.replace(/([A-Z])/g, '_$1').toLowerCase();

    if (specialParameters.some((x) => x === s)) {
        switch (s) {
            case 'date':
                modifiedParam = `$${modifiedParam}`;
                break;
            case 'uid':
                modifiedParam = 'id';
                break;
            case 'clientTempId':
            case 'isDirty':
            case '_templateId':
                modifiedParam = s;
                break;
            default:
                modifiedParam = `_${modifiedParam}`;
                break;
        }
    }

    return modifiedParam;
};

export function convertToCamelWithSpecialParameters<T>(
    o: any,
    parameters: Record<string, string>,
) {
    specialParameters = parameters;
    const convertedObject = convertToCamel<T>(o);
    specialParameters = {};
    return convertedObject;
}

export function convertToCamel<T>(o: any): T {
    if (isObject(o)) {
        const n: Record<string, any> = {};

        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = convertToCamel(o[k]);
        });

        return n as T;
    }
    if (Array.isArray(o)) {
        return o.map((i) => convertToCamel(i)) as any;
    }

    return o;
}

export function convertToSnake(o: any): any {
    if (isObject(o)) {
        const n: Record<string, any> = {};

        Object.keys(o).forEach((k) => {
            n[toSnakeCase(k)] = convertToSnake(o[k]);
        });

        return n;
    }
    if (Array.isArray(o)) {
        return o.map((i) => convertToSnake(i)) as any;
    }

    return o;
}
